import React from 'react'
import { Global } from '@emotion/react'

import { MediaContextProvider } from './src/contexts/responsive'
import HeaderProvider from './src/contexts/header/Provider'
import globalStyles from './global-styles';
import SWRProvider from './src/contexts/SWRProvider';

const Providers = ({ element }) =>
  <MediaContextProvider>
    <HeaderProvider>
      <SWRProvider>
        <>
          <Global styles={globalStyles} />
          {element}
        </>
      </SWRProvider>
    </HeaderProvider>
  </MediaContextProvider>

export default Providers
