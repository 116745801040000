import React from 'react'
import SVG from './SVG'

const Logo = (props) => {
  return (
    <SVG viewBox="0 0 605.72 123.65" {...props}>
      <g fill="currentColor">
        <path d="M68.42,122.2,43.73,69.48A.68.68,0,0,0,43,69H17.63a.62.62,0,0,0-.7.7v52a1.56,1.56,0,0,1-1.77,1.76H1.76A1.55,1.55,0,0,1,0,121.67V1.76A1.56,1.56,0,0,1,1.76,0H49.2A38,38,0,0,1,67.8,4.41,31,31,0,0,1,80.23,16.75a37.15,37.15,0,0,1,4.41,18.34q0,12.35-6.44,20.9T60.31,67.54c-.48.23-.59.52-.36.88l25.57,52.9a1.91,1.91,0,0,1,.18.88q0,1.23-1.41,1.23H70.36A1.92,1.92,0,0,1,68.42,122.2ZM16.93,15.34v39.5a.62.62,0,0,0,.7.71H47.08q9.35,0,15-5.56t5.64-14.72q0-9.35-5.64-15t-15-5.64H17.63A.62.62,0,0,0,16.93,15.34Z" />
        <path d="M188.32,14.64h-62.6a.62.62,0,0,0-.7.7V53.25a.62.62,0,0,0,.7.71h53a1.56,1.56,0,0,1,1.76,1.76V66.83a1.55,1.55,0,0,1-1.76,1.76h-53a.62.62,0,0,0-.7.71v38.79a.62.62,0,0,0,.7.71h62.6a1.56,1.56,0,0,1,1.76,1.76v11.11a1.55,1.55,0,0,1-1.76,1.76H109.85a1.55,1.55,0,0,1-1.76-1.76V1.76A1.56,1.56,0,0,1,109.85,0h78.47a1.56,1.56,0,0,1,1.76,1.76V12.87A1.56,1.56,0,0,1,188.32,14.64Z" />
        <path d="M303.85,121.67V1.76A1.56,1.56,0,0,1,305.61,0H319a1.56,1.56,0,0,1,1.77,1.76V108.09a.62.62,0,0,0,.7.71h60.84a1.56,1.56,0,0,1,1.76,1.76v11.11a1.55,1.55,0,0,1-1.76,1.76H305.61A1.55,1.55,0,0,1,303.85,121.67Z" />
        <path d="M483,122l-9.78-31.69a.68.68,0,0,0-.7-.53h-42.6a.69.69,0,0,0-.71.53L419.34,122a2.05,2.05,0,0,1-2.11,1.41H402.94c-1.29,0-1.76-.64-1.41-1.94L440.15,1.41A2.06,2.06,0,0,1,442.27,0H459.9A2,2,0,0,1,462,1.41l39,120.08.18.71q0,1.23-1.59,1.23H485.11A2.05,2.05,0,0,1,483,122ZM433.71,76.13a.84.84,0,0,0,.62.27h33.6a.84.84,0,0,0,.62-.27.48.48,0,0,0,.09-.61l-17-54.36a.58.58,0,0,0-1.06,0L433.62,75.52A.47.47,0,0,0,433.71,76.13Z" />
        <path d="M587,60.13q18.69,7.93,18.69,29.45,0,16.22-10.75,25t-28,8.81H523a1.55,1.55,0,0,1-1.76-1.76V1.76A1.56,1.56,0,0,1,523,0h42.5Q583.87,0,594,8.38T604.14,32.8q0,19-17.11,26.27C586.44,59.42,586.44,59.78,587,60.13ZM538.19,15.34V52.55a.62.62,0,0,0,.7.7h26.63q10.23,0,16.13-5.11t5.91-13.93q0-9-5.91-14.28t-16.13-5.29H538.89A.62.62,0,0,0,538.19,15.34Zm44.7,87.73Q588.8,97.51,588.8,88t-6-15.08q-6-5.55-16.4-5.55H538.89a.62.62,0,0,0-.7.7v39.86a.62.62,0,0,0,.7.7h28Q577,108.62,582.89,103.07Z" />
        <path d="M247.53,74.68A24.49,24.49,0,1,0,272,99.16,24.48,24.48,0,0,0,247.53,74.68Zm0,38.81a14.33,14.33,0,1,1,14.33-14.33A14.34,14.34,0,0,1,247.53,113.49Z" />
        <circle cx="247.53" cy="27.92" r="22.44" />
      </g>
    </SVG>
  )
}

export default Logo
